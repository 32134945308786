import notAuthImg from "@src/assets/images/pages/not-authorized.svg"
import logoCyberId from "@src/assets/images/logo/logo-cyberid.png"

import "@styles/base/pages/page-misc.scss"

const UserConnected = () => {
  return (
    <div className="misc-wrapper">
      <a className="brand-logo" href="/">
        <img src={logoCyberId} height={32}></img>
      </a>
      <div className="misc-inner p-2 p-sm-3">
        <div className="w-100 text-center">
          <h2 className="mb-1">User is connected! 🔐</h2>
          <p className="mb-2"></p>
          <img
            className="img-fluid"
            src={notAuthImg}
            alt="Not authorized page"
          />
        </div>
      </div>
    </div>
  )
}
export default UserConnected
